import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Collapse from 'react-bootstrap/Collapse';
import { Helmet } from 'react-helmet';
import Safe from 'react-safe';
import {
  Button,
  Container,
  Carousel,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavDropdown,
  Form,
  FormControl,
  FormGroup,
  ButtonToolbar,
  TabContainer,
  TabContent,
  TabPane,
} from 'react-bootstrap';
// import ResponsiveEmbed from 'react-responsive-embed';

// import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';

import { useState } from 'react';
import Link from 'gatsby-link';
// import Title from '../components/title';

// import { addScript } from '../helpers/demo';
// import { navigateToLink } from '../helpers/demo';
const site = {};

import Layout from 'components/layout.js';

// import Box from '../components/box';
// import Head from '../components/head';
import JSONData from '../../content/offerings-dpt-business-problems.json';
import JSONDataConsumers from '../../content/offerings-dpt-customer-problems.json';
import JSONDataPartners from '../../content/offerings-dpt-partner-problems.json';
import JSONDataInsurance from '../../content/offerings-dpt-insurance-problems.json';
const decoratorStyles = {
  border: 'solid 1px #2B7CE9',
  color: 'white',
  borderRadius: 2,
  backgroundColor: '#5596ed',
};

const Decorator = props => {
  return (
    <button
      style={decoratorStyles}
      // onClick={}
    >
      Click Me
    </button>
  );
};

Decorator.propTypes = {
  id: PropTypes.string,
};
 

site.title = process.env.REACT_APP_NAME;

site.title = 'Team - '+site.title;
const style = { height: '90vh', width: '90vw' };

const networkProps = {
  style,
  options: {
    layout: {
      randomSeed: 42,
    },
  },
};
import gsap from 'gsap';
var createReactClass = require('create-react-class');

class Company extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}
  render() {
    require('./company.css');
    return (
      <div>
        <Layout>
          {/* <div
          dangerouslySetInnerHTML={{
            __html: data.aboutJson.content.childMarkdownRemark.html,
          }}
        />   */}
          {/* <Box>  */}

          <main role="main">
            <div className="wrap" style={{ maxWidth: '800px' }}>
              <h1 className="split"> </h1>
            </div>
            <div className="jumbotron header2">
              <div className="container">
                <h1 className="display-32">{JSONData.title}</h1>
              </div>
            </div>

            <div className="container">
              {/* <h3>Solutions For</h3> */}
              <br />
              <TabContainer id="solutions" defaultActiveKey="consumers">
                <Row>
                  <Col md={2}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="consumers">Consumers</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="business">Businesses</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="insurance">
                          Insurance Companies
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="partners">Partners</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col md={10}>
                    <TabContent>
                      <TabPane eventKey="consumers">
                        <Cards
                          JSONData={JSONDataConsumers}
                          cssClass=" border-primary"
                        />
                      </TabPane>
                      <TabPane eventKey="business">
                        <Cards JSONData={JSONData} cssClass=" border-primary" />
                      </TabPane>
                      <TabPane eventKey="insurance">
                        <Cards
                          JSONData={JSONDataInsurance}
                          cssClass=" border-primary"
                        />
                      </TabPane>{' '}
                      <TabPane eventKey="partners">
                        <Cards
                          JSONData={JSONDataPartners}
                          cssClass=" border-primary"
                        />
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </TabContainer>
            </div>
          </main>
        </Layout>
      </div>
    );
  }
}

Company.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Company;

// const query = graphql`
//   query CompanyQuery {
//     companyJson {
//       title
//       content {
//         childMarkdownRemark {
//           html
//         }
//       }
//     }
//   }
// `;

// import DemoTabs from '../components/slides/tabs';

//In fact, hardware and software had defects that he had known about since he started programming computers professionally self taught in his teens on PCs, Linux, mobile, virtual reality and even supercomputers.

const CarouselTeam = () => (
  <div className="jumbotron">
    <div className="container">
      <Carousel
        //  fade="true"

        indicators="false"
        controls="false"
        interval="15000"
        // style={{ zIndex: 50}}
      >
        <Carousel.Item style={{ height: '400px !important', color: 'white' }}>
          {/* <img
                          className="d-block w-100"
                          src="holder.js/800x400?text=First slide&bg=373940"
                          alt="First slide"
                        /> */}
          <Carousel.Caption>
            <h3 className="text-white">
              Our shared future, refined and optimised.
            </h3>
            <p className="text-white">
              No more time wasted on the wrong problems, ideas, technologies,
              solutions, services, energy &amp; resources!
            </p>
            {/* <p className='text-white'>
          <Button variant='primary'>
            Learn more
          </Button>
        </p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item style={{ height: '400px !important', color: 'white' }}>
          {/* <img
                          className="d-block w-100"
                          src="holder.js/800x400?text=Second slide&bg=282c34"
                          alt="Third slide"
                        /> */}
          <Carousel.Caption>
            <h3 className="text-white">
              The world's trusted computing and privacy platform.
            </h3>
            <p className="text-white">
              We’re simplifying digital privacy for consumers with the worlds
              first personal computing platform, cloud, OS and AI.™.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item style={{ height: '400px !important', color: 'white' }}>
          {/* <img
                          className="d-block w-100"
                          src="holder.js/800x400?text=Third slide&bg=20232a"
                          alt="Third slide"
                        /> */}
          <Carousel.Caption>
            <h3 className="text-white">
              A simpler way to live, work, create, play, invent, research and
              share.
            </h3>
            <p className="text-white">
              We're solving reducing your waste, risks, headaches and maximizing
              your value and joy.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item style={{ height: '400px !important', color: 'white' }}>
          {/* <img
                          className="d-block w-100"
                          src="holder.js/800x400?text=Third slide&bg=20232a"
                          alt="Third slide"
                        /> */}
          <Carousel.Caption>
            <h3 className="text-white">
              A better, smarter &amp; safer world and future for all.
            </h3>
            <p className="text-white">
              We’re helping people and organizations improve the future.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      {/* <p className="text-white">
      <Link to="/" variant="primary">
        Learn more
      </Link>
    </p> */}
      <hr />
      {/* <LearnMore /> */}
    </div>
  </div>
);

const TimelineVertical = () => (
  <Timeline lineColor={'#000'}>
    {/* <TimelineItem
      key="001"
      dateText="2010 - Launched first idea"
      style={{ color: '#000' }}
    >
      <h3>First Idea</h3>
      <h4>Domain, brand, website owner threat monitoring</h4>
      <p>
        While protecting and trusted by high valued assets such as multi million
        dollar web domains for businesses, high end needs clients, celebrities,
        high net worth individuals, this idea was generated at a time when ad
        networks were being infected. Major sites were being blacklisted by
        StopBadWare.org sponsored by Google. We needed better tools and systems
        to get early warning indications of the threats.
         In addition, it was observed while working to build custom software for enterprise IT solutions for federal agencies and other organizations certain standards had to be passed as defined by private pen testing tools, now acquired by IBM, Omar noticed that engineers and developers weren't doing enough to ensure privacy and security. It was almost never being baked in to the hardware or software.  
      </p>
      <p>
    BadWareMonitor.com - Idea was simple just wanted a simple monitoring solution if a website or domain had a threat.
    </p>
    <p>
    Instead turned into a cybersecurity, risk and threat trend monitoring watchdog AI bot for Twitter <a href="https://twitter.com/badwaremonitor/">@BadWareMonitor</a>.
    </p>  
    </TimelineItem> */}
    {/* <TimelineItem
    key="002"
    dateText="04/2009 – 11/2010"
    dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
    bodyContainerStyle={{
      background: '#ddd',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
    }}
  >
    <h3 style={{ color: '#61b8ff' }}>Title, Company</h3>
    <h4 style={{ color: '#61b8ff' }}>Subtitle</h4>
    <p>
      Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
      exercitation. Veniam velit adipisicing anim excepteur nostrud magna
      nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
      reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
      est.
    </p>
    <p>
      Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
      exercitation. Veniam velit adipisicing anim excepteur nostrud magna
      nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
      reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
      est.
    </p>
  </TimelineItem> */}
    {/*  
 <TimelineItem
    key="003"
    dateComponent={(
      <div
        style={{
          display: 'block',
          float: 'left',
          padding: '10px',
          background: 'rgb(150, 150, 150)',
          color: '#fff',
        }}
      >
        April 15, 2014
      </div>
    )}
  >
    <h3>Advised top US defense contractors of threat of not applying custom end to end encryption to all cloud services using AWS GovCloud.</h3>
    <br/>   <br/> <h4>While working as a SME consulting advisor designing and implementing proprietary tamper-detecting, auditable military asset supply chain security technology</h4>
    <p> <br/>
      <blockquote>Omar - why should we use an extra layer of encryption? DUH - SSL is not secure - the same week Google tried to recruit Omar to join Senior Management team and Heartbleed was revealed by Google security team.</blockquote>
    </p>
    <p>
      
    </p>
  </TimelineItem> */}
    <TimelineItem
      key="003"
      dateComponent={
        <div
          style={{
            display: 'block',
            float: 'left',
            padding: '10px',
            background: 'rgb(150, 150, 150)',
            color: '#fff',
          }}
        >
          April 15, 2017
        </div>
      }
    >
      <h3>{site.title} VISION</h3> <br />
      {/* <h4>Discussing Cambridge Analytica and Facebook privacy woes</h4> <br /> */}
    </TimelineItem>

    <TimelineItem
      key="003b"
      dateComponent={
        <div
          style={{
            display: 'block',
            float: 'left',
            padding: '10px',
            background: 'rgb(150, 150, 150)',
            color: '#fff',
          }}
        >
          October 2017
        </div>
      }
    >
      <h3>{site.title} FORMED</h3> <br />
      {/* <h4>Discussing Cambridge Analytica and Facebook privacy woes</h4> <br /> */}
    </TimelineItem>
    {/* <TimelineItem
    key="004"
    dateText="FUTURE"
    dateInnerStyle={{ background: '#76bb7f' }}
  >
    <h3>{site.title} Early Access - Public Beta</h3> <br/> 
       
  </TimelineItem> */}
    <TimelineItem
      key="004"
      dateText="Present"
      dateInnerStyle={{ background: '#76bb7f' }}
    >
      <h3>
        <a href="/about/">{site.title} Private Beta Launched!</a>
      </h3>{' '}
      <br />
      {/* <h4>The {site.title}.com Platform Vision Private Beta in R&amp;D.</h4> */}
    </TimelineItem>
  </Timeline>
);

// import { LandingApp, Page, Section, Hero, Navbar2, HorizontalSplit, Code,
//   CustomerQuotes,
//   Countdown,
//   Footer,
//   Figure,
//   ImageList,
//   PricingTable,
//   SignupInline,
//   SignupModal,
//   Team } from "neal-react";
var ReactRotatingText = require('react-rotating-text');
// import '../../static/js/TweenMax.min.js';
// import '../../static/js/demo.js';
const appName = 'Enlightened';
const data = {};

const typingItems = ['Knowledge', 'Human', 'Enlightened'];

site.title = 'Team';

var cols = [
  { key: 'id', label: 'Id' },
  { key: 'userId', label: 'User' },
  { key: 'title', label: 'Title' },
  { key: 'body', label: 'Body' },
];

var data2 = [
  {
    userId: 1,
    id: 1,
    title:
      'sunt aut facere repellat provident occaecati excepturi optio reprehenderit',
    body:
      'quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto',
  },
  {
    userId: 1,
    id: 2,
    title: 'qui est esse',
    body:
      'est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla',
  },
];

var referencesData = [
  {
    id: 1,
    title:
      'sunt aut facere repellat provident occaecati excepturi optio reprehenderit',
    body:
      'quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto',
  },
  {
    id: 2,
    title: 'qui est esse',
    body:
      'est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla',
  },
];
var causesData = [
  {
    id: 1,
    title:
      'sunt aut facere repellat provident occaecati excepturi optio reprehenderit',
    body:
      'quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto',
  },
  {
    id: 2,
    title: 'qui est esse',
    body:
      'est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla',
  },
];
var referencesData = [
  {
    id: 1,
    title:
      'sunt aut facere repellat provident occaecati excepturi optio reprehenderit',
    body:
      'quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto',
  },
  {
    id: 2,
    title: 'qui est esse',
    body:
      'est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla',
  },
];
const config = { appDashboardPath: process.env.REACT_APP_appDashboardPath };

class Competitors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      related: [
        {
          Name: 'Unity',
          Who: "Je'daii",
          // 'Description': ` `,
          //  'URL': `https://starwars.fandom.com/wiki/Je%27daii_Order`,
          Code: `There is no ignorance, there is knowledge.<br/>
        There is no fear, there is power.<br/>
        I am the heart of the Force.<br/>
        I am the revealing fire of light.<br/>
        I am the mystery of darkness.<br/>
        In balance with chaos and harmony,<br/>
        Immortal in the Force.`,
        },
        {
          Name: 'The Light Side',
          Who: 'Jedi',
          //  'Description': ` `,
          //  'URL': `https://en.wikipedia.org/wiki/Jedi`,
          Code: `There is no emotion, there is peace.<br/>
        There is no ignorance, there is knowledge.<br/>
        There is no passion, there is serenity.<br/>
        There is no chaos, there is harmony.<br/>
        There is no death, there is the Force.`,
        },
        {
          Name: 'The Gray Side',
          Who: 'Gray Jedi',
          // 'Description': ` `,
          //  'URL': `https://en.wikipedia.org/wiki/Jedi`,
          Code: `There is no emotion, there is peace.<br/>
        There is no ignorance, there is knowledge.<br/>
        There is no passion, there is serenity.<br/>
        There is no chaos, there is harmony.<br/>
        There is no death, there is the Force.`,
        },
        {
          Name: 'The Dark Side',
          Who: 'Sith',
          // 'Description': ` `,
          //  'URL': `https://en.wikipedia.org/wiki/Jedi`,
          Code: `Peace is a lie. There is only Passion.<br/>
        Through Passion I gain Strength.<br/>
        Through Strength I gain Power.<br/>
        Through Power I gain Victory.<br/>
        Through Victory my chains are Broken.<br/>
        The Force shall free me.`,
        },
      ],
      similar: [
        {
          Name: 'Yelp',
          // 'Score': 15,
          Description: `
        <b>Yelp</b> - a digital yellow pages search engine and app for
        reviews and listings of businesses{' '}
        <span className="competitorAbout">
          but {appName} is without the anonymous and subjective user reviews
        </span>`,
        },
        {
          Name: 'Glassdoor',
          //  'Score': 43,
          Description: `
        <b>Glassdoor</b> - a website in the job market space for talent
        interested in posting or evaluating company ratings and anonymous
        reviews that employers pay a lot of money for per year{' '}
        <span className="competitorAbout">
          but {appName} is without the anonymous reviews
        </span>`,
        },
        {
          Name: 'Property Land Records for Real Estate',
          // 'Score': 15,
          Description: `
        <b>Property Land Records for Real Estate</b> - living documents
        which professionals like title search companies and lawyers charge
        fees to check land record ledger or 'books' to see if anyone has a
        lien against a property when applying for a new loan to ensure the
        property has no other loan and interest on it.
       `,
        },
        {
          Name: 'Credit Scores and Credit History Reports for People',
          // 'Score': 43,
          Description: `
        <b>Credit Scores and Credit History Reports for People</b> - private database of
        people's financial and personal information aggregated, monetized
        and controlled by private corporations called credit bureaus and
        forced onto consumers when they apply for credit and creditors for
        their clients are given permission by those same consumers to
        check their reports to approve new credit but first credit
        worthiness is checked such as FICO scores, and negative and
        positive history events in the profile and compared to required
        thresholds to ensure customer won't be a risk to their investment
        of money and payment of loan and interest.{' '}
        <span className="competitorWeakness">
          A major issue with this is no consent was ever given by the
          consumer to collect, mine, sell this information and when
          there's a breach they offer you 1 year of identity theft
          services, we think you can do better Equifax!
        </span>
        <span className="competitorAbout">
          {' '}
          {appName} is for tech, apps, sites, and anyone using tech in their
          organization or lives and helps increase confident, trust,
          safety and privacy while being affordable since it's free for
          anyone to check a basic report.
        </span>`,
        },
        {
          Name: 'Carfax',
          //  'Score': 30,
          Description: `Carfax Accident Reports for Vehicles</b> - used by consumers to
        check history, risk and value of a vehicle purchased from used car
        dealers or on the street person to person since a person's word
        can't be trusted that the car is in good condition and they may
        not know the previous history of owners or how they well they
        maintained the vehicle as well.{' '}
        <span className="competitorWeakness">
          One issue with this - everyone's PII (name) is recorded
        </span>{' '}
        in this permanent history attached to the VIN ID number of the
        car.`,
        },
        {
          Name: 'eBay',
          Score: 87,
          Description: `eBay Online Auction Reputation Rating and Review System</b> -
        used by consumer buyers and sellers to check history, risk and
        trust of a buyer or seller behind purchased from used car dealers
        or on the street person to person since a person's word can't be
        trusted and they may not know the previous history of seller or
        buyer or how they well they handle service and cooperation as
        well.`,
        },
        {
          Name: 'Rotten Tomatoes',
          // 'Score': 28,
          Description: `Rotten Tomatoes movie critic score and audience rating system
        for films and movies`,
        },
      ],
    };
  }

  render() {
    return (
      <div className="App">
        {/* <h4>{site.title}  </h4> */}
        {/* <br/>Many organizations have come in the past... */}
        <Table2 data={this.state.related} />
        {/* <TableReactable columns={[{
      name: 'Name',
      selector: 'name',
      className: 'w-50',
      // …
    },{name: 'Description',selector: 'description'}]} data={[
    {name: 'Handcrafted Cotton Keyboard',description: 'Omnis aliquam libero voluptatem qui qui et. Est au…'},
    {name: 'Licensed Soft Soap',description: 'Odio debitis est repudiandae autem. Rerum omnis no…'},
    {name: 'Refined Concrete Soap',description: 'Atque quod quas blanditiis eveniet. Alias deserunt…'},
    // …
  ]} /> */}
        {/* <br/> 
          <h2>We are is similar to</h2>
          <Table2 data={this.state.similar}/> */}
      </div>
    );
  }
}

class Table2 extends React.Component {
  constructor(props) {
    super(props);
    this.getHeader = this.getHeader.bind(this);
    this.getRowsData = this.getRowsData.bind(this);
    this.getKeys = this.getKeys.bind(this);
  }

  getKeys = function() {
    return Object.keys(this.props.data[0]);
  };

  getHeader = function() {
    var keys = this.getKeys();
    return keys.map((key, index) => {
      // if (index=='key')
      // continue;
      return (
        <th style={{ textAlign: 'left' }} key={key}>
          {key.toUpperCase()}
        </th>
      );
    });
  };

  getRowsData = function() {
    var items = this.props.data;
    var keys = this.getKeys();
    return items.map((row, index) => {
      // if (index=='key')
      // continue;
      return (
        <tr key={index}>
          <RenderRow key={index} data={row} keys={keys} />
        </tr>
      );
    });
  };

  render() {
    return (
      <div>
        <table>
          <thead>
            <tr>{this.getHeader()}</tr>
          </thead>
          <tbody>{this.getRowsData()}</tbody>
        </table>
      </div>
    );
  }
}

const RenderRow = props => {
  return props.keys.map((key, index) => {
    return (
      <td style={{ textAlign: 'left' }} key={props.data[key]}>
        <div
          dangerouslySetInnerHTML={{
            __html: props.data[key],
          }}
        />
      </td>
    );
  });
};

function LearnMore() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="outline-light"
        onClick={() => setOpen(!open)}
        aria-controls="learn-more-text"
        aria-expanded={open}
      >
        Learn More
      </Button>
      <br />
      <br />
      <Collapse in={open}>
        <div id="learn-more-text">
          <div className=" ">
            <hr />
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3>References</h3>
                  {/* <References /> */}

                  <p />
                </div>
              </div>{' '}
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>

          {/* <p className="text-white">
            <Button href="/" variant="primary">
              Learn more
            </Button>
          </p>{' '} */}
        </div>
      </Collapse>
    </>
  );
}

const Cards = ({ JSONData, cssClass = ' border-primary' }) => (
  <div className="container ">
    {/* <h2>{JSONData.title}</h2> */}
    <div
      className=" justify-content-center3 text-center"
      style={
        {
          // height: '500px',
          // width: '100%',
          // maxWidth: ' 18rem;',
        }
      }
    >
      {/* <h2>{JSONData.title}</h2> */}

      {/* <Carousel
            //  fade="true"

            indicators="false"
            controls="false"
            interval="15000"
            style={{ zIndex: 50}}
          >
            <Carousel.Item
              style={{ height: '500px !important', color: 'white' }}
            > 
              <Carousel.Caption>
                <h1>Our Vision is to create a better, smarter &amp; safer future.</h1>
 
              </Carousel.Caption>
            </Carousel.Item> 
          </Carousel>
     */}

      {/* <hr /> */}
      <div className=" grid-container text-left2 ">
        {/* <Carousel data-interval="5000" style={{ color: '#ddd', zIndex: 50 }}> */}
        {JSONData.content.map((data, index) => {
          if (data.title == '' || data.title == null) return;
          var link = '';
          var linkHTML = '';
          if (data.url)
            if (data.url != '' && data.url != null) {
              link = data.url;
              // linkHTML =                "<p className='card-text text-white '><a href='" + link + "'>"+data.url+"</a></p>";
            }

          var role = '';
          var roleHTML = '';
          // if (data.role)
          // if (data.role != '' && data.role != null) {
          //   role = data.role;
          //   roleHTML =
          //     "<p className='card-text'>" + role + "</p>";
          // }
          var img = '';
          var imgHTML = '';
          if (data.img)
            if (data.img != '' && data.img != null) {
              img = data.img;
              imgHTML =
                '<img className="card-img-top img-fluid" src="' +
                img +
                '" alt="' +
                data.title +
                '" >';
            }

          return (
            <div
              // key={`2content_item_${index}`}
              className={'card   text-white2  ' + cssClass}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: ' ' + imgHTML + ' ',
                }}
              />
              <div
                className="card-body"
                dangerouslySetInnerHTML={{
                  __html:
                    '<p className="card-title"><b style="font-weight: 700;">' +
                    data.title +
                    '</b></p>  ' +
                    '  <p className="card-text text-white2 ">' +
                    data.item +
                    linkHTML +
                    roleHTML +
                    ' </p>',
                }}
              >
                {/* <h5 className="card-title">{data.item}</h5> */}
                {/* <p className="card-text">   */}

                {/* {data.url != '' ? (
                  <Link  key={`2content_link_${index}`} to={link} className="btn btn-primary">
                    more
                  </Link>
                ) : null} */}
                {/* </p>    */}
              </div>
              {/* <div className="card-footer">
            <small className="text-muted">Last updated 3 mins ago</small>
          </div>  */}
              <br />{' '}
            </div>
          );
        })}
        {/* </Carousel> */}
      </div>
    </div>
  </div>
);

// <Carousel.Item
// // style={{ height: `auto !important` }}
// >
//   <Carousel.Caption
//   // style={{ position: `auto !important` }}
//   >
//     <div key={`2content_item_${index}`}>
//       <span
//         className="home-testimonial text-white"
//         style={{ fontWeight: '200' }}
//         dangerouslySetInnerHTML={{
//           __html:
//             ' <blockquote className="quote"> ' +
//             data.item +
//             ' </blockquote><br/><br/><p>' +
//             data.author +
//             '</p>',
//         }}
//       />
//     </div>
//   </Carousel.Caption>
// </Carousel.Item>

const People2 = () => (
  <div>
    <h2>{JSONData.title}</h2>
    <div className="container   ">
      <div className="row  justify-content-center" style={{ width: '100%' }}>
        <div
          className="noPadding2 center-block2"
          style={{ padding: '0px', marginBottom: '0px' }}
        >
          {JSONData.content.map((data, index) => {
            return
            <div className="carousel-item">
              <div className="carousel-caption">
                <div className="row">
                  <div className="col-sm-3">
                    <img
                      src="http://via.placeholder.com/200x200"
                      alt=""
                      className="rounded-circle img-fluid"
                    />
                  </div>
                  <div className="col-sm-9">
                    <div key={`2content_item_${index}`}>
                      <span
                        className="home-testimonial"
                        style={{ fontWeight: '200' }}
                        dangerouslySetInnerHTML={{
                          __html:
                            ' <span className="quote">' +
                            data.item +
                            '</span><br/><br/><p>' +
                            data.author +
                            '</p>',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>;
          })}
        </div>
        <div className="float-right navi">
          <Link
            className=""
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <span className="carousel-control-prev-icon ico" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </Link>
          <Link
            className=""
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <span className="carousel-control-next-icon ico" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

{
  /* <!-- /.container --> */
}

{
  /* <div className="container">
        <h1>History</h1>
        <TimelineVertical />
      </div> */
}

{
  /* <hr/> */
}
{
  /* <Timeline lineColor={'#ddd'}>
        <TimelineItem
          key="001"
          dateText="11/2010 – Present"
          style={{ color: '#e86971' }}
        >
          <h3>Title, Company</h3>
          <h4>Subtitle</h4>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
        </TimelineItem>
        <TimelineItem
          key="002"
          dateText="04/2009 – 11/2010"
          dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
          bodyContainerStyle={{
            background: '#ddd',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
          }}
        >
          <h3 style={{ color: '#61b8ff' }}>Title, Company</h3>
          <h4 style={{ color: '#61b8ff' }}>Subtitle</h4>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
        </TimelineItem>
        <TimelineItem
          key="003"
          dateComponent={
            <div
              style={{
                display: 'block',
                float: 'left',
                padding: '10px',
                background: 'rgb(150, 150, 150)',
                color: '#fff',
              }}
            >
              11/2008 – 04/2009
            </div>
          }
        >
          <h3>Title, Company</h3>
          <h4>Subtitle</h4>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
        </TimelineItem>
        <TimelineItem
          key="004"
          dateText="08/2008 – 11/2008"
          dateInnerStyle={{ background: '#76bb7f' }}
        >
          <h3>Title, Company</h3>
          <h4>Subtitle</h4>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
        </TimelineItem>
      </Timeline> */
}

{
  /* We're interested in Technology, Science, AI, Security,
              Privacy &amp; the future. */
}
{
  /* <ul className="timeline">
                <li className="timeline-event">
                  <label className="timeline-event-icon" />
                  <div className="timeline-event-copy">
                    <p className="timeline-event-thumbnail">April 1995 - 1997</p>
                    <h3>High School, Employment + Early under grad</h3>
                    <h4>
                      While attending school - also a research engineer doing
                      computer science, simulations, super computing, mobile
                      architecture, programming, engineering and hacking
                    </h4>
                    <p>
                      A disclosure from some events back in 1996 when Omar was
                      in high school studying in the famous home town of Mark
                      Twain, Ole Miss, Oxford, Mississippi, at age 16, he was
                      also an employed R&D engineer and taking undergrad &
                      graduate courses in computer science + mentored &amp; hired by
                      comp sci, electrical, civil &amp; hydroscience depts:
                    </p>{' '}
                    <p>
                      {' '}
                      Omar was lead computer science research engineer with
                      Microsoft funded research project to develop C based PDA
                      on-device app compiler &amp; runtime
                    </p>{' '}
                    <p>
                      {' '}
                      While working on supercomputer physics simulations of 9.0
                      earthquakes - Omar also found vulnerabilities in Cray
                      Unicos operating systems - a multi-million dollar
                      supercomputer by developing hacks that broke memory safety
                      vulnerabilities due to weaknesses in operating system C
                      code dealing with memory management.
                    </p>{' '}
                    <p>
                      {' '}
                      Omar tested and showed the same issues existed along with
                      Windows, Linux, mobile devices, many other systems &
                      devices just experimenting on the side.
                    </p>
                    <p>
                      Omar found the master code to all Ford button code based
                      keyless entry systems because his mom and dad would hide
                      the soda from him and when they bought a Ford Crown
                      Victoria and started putting the soda crates in the trunk
                      and locked the car and kept the keys tight - he learned
                      this vulnerability and then over time his parents were
                      surprised how the soda could go a miss when no one was
                      drinking it and Omar could never have the key to get in
                      nor was the code given or accidentally shown to Omar. They
                      eventually found out through the empty cans in the trash
                      most likely.
                    </p>
                    <p><strong>Schwerpunkt: Frontend-Entwicklung</strong><br/>Entwickeln von anspruchsvollen, animierten, responsive und adaptive Webseiten mit HTML5, SCSS, jQuery; für alle Browser, optimiert für Desktop, Notebook, Smartphones und Tablets (iOS, Android, Windows).</p>
                    <p><strong>Projektmanagement mit Scrum</strong><br/>Ständiges Verbessern des agilen Entwicklungsprozesses beispielsweise durch Grunt, Yeoman, GIT, JIRA und BrowserStack.</p>
                  </div>
                </li>
                <li className="timeline-event">
                  <label className="timeline-event-icon" />
                  <div className="timeline-event-copy">
                    <p className="timeline-event-thumbnail">June 2000</p>
                    <h3>Project Manager</h3>
                    <h4>
                      After dropping out of undergrad in electrical engineering
                    </h4>
                    <p>
                      Joined worlds first gnu Linux certification standard
                      startup as a project manager of Dev team - recruited by
                      Another comp sci professor who founded it - then acquired
                      by Thomson learning
                    </p>{' '}
                    <p>
                      {' '}
                      Omar dropped out of college when he Got offered job offers
                      including from
                    </p>{' '}
                    <p>
                      {' '}
                      Missile defense contractors impressed by multitude of
                      experimental advanced high tech r&d and Science, tech,
                      math and cross engineering disciplines seeking Omar’s help
                      in real-time satellite and missile defense control and
                      interface systems but also for visualization, simulation,
                      automation, communication challenges
                    </p>{' '}
                    <p>
                      {' '}
                      Lead all tech at global travel startup and get to build
                      the team and learn business
                    </p>
                  </div>
                </li>
                <li className="timeline-event">
                  <label className="timeline-event-icon" />
                  <div className="timeline-event-copy">
                    <p className="timeline-event-thumbnail">April 2014</p>
                    <h3>Founder &amp; CEO of UVision Consulting</h3>
                    <h4>Strategic Advisor and Technology Consultant</h4>
                    <p>
                      Omar was invited to be SME advisor on DARPA SUPPLY CHAIN
                      SECURITY project by Defense contractors including Program
                      managers &amp; executives from Lockheed Martin were advised on
                      the risks of HTTPS vulnerabilities to DOD supply chain
                      project which Omar was also technology solution
                      architecture advisor on after recently advising another
                      encrypted mobile enterprise communications company on
                      technology strategy.
                    </p>{' '}
                    <p>
                      {' '}
                      While Omar was working on the project &amp; a new startup -
                      The same week (and 2 times after) Google was trying hard
                      to recruit Omar for leadership roles (“we’ve been tracking
                      you for around 5 years -- you’re in the top 5% of all
                      candidates globally, don’t quit what you’re doing - join
                      us”) - some time after that Heartbleed was discovered by
                      Google security team - coincidence? Some might argue that
                      Heartbleed is the worst vulnerability found (at least in
                      terms of its potential impact) since commercial traffic
                      began to flow on the Internet.[30]
                    </p>
                  </div>
                </li>
              </ul> */
}
